import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/logo.png'


const _hoisted_1 = {
  class: "ui vertical masthead center aligned",
  id: "header"
}
const _hoisted_2 = { id: "header-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: "/"
      }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_ctx.subHeader), 1)
    ])
  ]))
}