import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "ui basic unstackable table",
  id: "table"
}
const _hoisted_2 = { id: "servers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_item = _resolveComponent("table-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("table", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", { id: "status4" }, "运行状态"),
          _createElementVNode("th", { id: "name" }, "名称"),
          _createElementVNode("th", { id: "type" }, "类型"),
          _createElementVNode("th", { id: "location" }, "位置"),
          _createElementVNode("th", { id: "uptime" }, "上线时间"),
          _createElementVNode("th", { id: "load" }, "负载"),
          _createElementVNode("th", { id: "network" }, "网络 ↓|↑"),
          _createElementVNode("th", { id: "traffic" }, "流量 ↓|↑"),
          _createElementVNode("th", { id: "cpu" }, "CPU"),
          _createElementVNode("th", { id: "ram" }, "内存"),
          _createElementVNode("th", { id: "hdd" }, "硬盘")
        ])
      ], -1)),
      _createElementVNode("tbody", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servers, (server, index) => {
          return (_openBlock(), _createBlock(_component_table_item, {
            key: index,
            server: server
          }, null, 8, ["server"]))
        }), 128))
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "w_text" }, " * 当负载超过了50%(0.50),服务器处理效能会降低,并会在运行状态上显示过载中且背景改为黄色. ", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "w_text" }, " * 当负载超过了150%(1.5),服务器负载达到极限值,并会在运行状态上显示过载中且背景改为橙色. ", -1))
  ], 64))
}