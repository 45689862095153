<template>
  <table class="ui basic unstackable table" id="table">
    <thead>
      <tr>
        <th id="status4">运行状态</th>
        <th id="name">名称</th>
        <th id="type">类型</th>
        <th id="location">位置</th>
        <th id="uptime">上线时间</th>
        <th id="load">负载</th>
        <th id="network">网络 ↓|↑</th>
        <th id="traffic">流量 ↓|↑</th>
        <th id="cpu">CPU</th>
        <th id="ram">内存</th>
        <th id="hdd">硬盘</th>
      </tr>
    </thead>
    <tbody id="servers">
      <!-- Servers here \o/ -->
      <!--
    use index for the key may cause performance issues when delete a server from array,
    but not a big matter and we cannot find a more suitable data for the unique key.
    -->
      <table-item v-for="(server, index) of servers" :key="index" :server="server" />
    </tbody>
  </table>
  <p class="w_text"> * 当负载超过了50%(0.50),服务器处理效能会降低,并会在运行状态上显示过载中且背景改为黄色. </p>
  <p class="w_text"> * 当负载超过了150%(1.5),服务器负载达到极限值,并会在运行状态上显示过载中且背景改为橙色. </p>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import TableItem from '@/components/TableItem.vue';

export default defineComponent({
  name: 'ServersTable',
  props: {
    servers: {
      type: Array as PropType<Array<StatusItem | BoxItem>>,
      default: () => ([])
    }
  },
  components: {
    TableItem
  }
});
</script>
<style>
.w_text {
  color: #000000;
}

#table {
  font-size: 1rem;
  border: none;
  text-align: center;
  vertical-align: middle;
  background-color: #000;
  opacity: 0.75;
}

.ui.table tr {
  background-color: #282828;
}

.ui.table tr td {
  color: #fff;
}

#table thead tr th {
  color: #fff;
  white-space: nowrap;
}
</style>
