import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, _cache[0] || (_cache[0] = [
    _createTextVNode(" 若長時間出現此消息,請確保您已啟用Javascript!"),
    _createElementVNode("br", null, null, -1),
    _createTextVNode("否則雲監控主服務（服務端）未啟動或已關閉。 ")
  ])))
}